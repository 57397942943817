@import '~bootstrap/scss/bootstrap.scss';
@import '~swiper/css/swiper.css';
@import '~animate.css/animate.min.css';
@import '~lightgallery.js/dist/css/lightgallery.css';
@import '../css/icons.min.css';

@import 'variables';
@import 'helper';
@import 'common';
@import 'preloader';
@import 'header';
@import 'slider';
@import 'support';
@import 'section-title';
@import 'product';
@import 'blog';
@import 'footer';
@import 'banner';
@import 'suscribe';
@import 'about';
@import 'image-slider';
@import 'testimonial';
@import 'brand-logo';
@import 'timers';
@import 'breadcrumb';
@import 'sidebar';
@import 'shop';
@import 'product-details';
@import 'cart';
@import 'compare';
@import 'checkout';
@import 'contact';
@import 'blog-details';
@import 'login-register';
@import 'my-account';
@import '404';
@import 'ghd';
